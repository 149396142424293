<template>
  <div id="app">
    <!-- leave-active-class="animate__animated animate__zoomOut animate__faster" -->
    <!-- <my-float-btn></my-float-btn> -->
    <transition name="slide" enter-active-class="animate__animated animate__zoomIn my-delay-0_5s my-element-1_5s" >
      <router-view v-if="isRresh"></router-view>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'App',
  data: ()=>({
    isRresh: true
  }),
  provide() {
    return {
      refresh: this.refresh
    }
  },
  methods: {
    refresh() {
      this.isRresh = false;
      this.$nextTick(() => {
        this.isRresh = true;
      })
    }
  }

}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

ul,
li,
ol {
  list-style: none;
}

html,
body {
  width: 100%;
  height: 100%;
  background: #ececec;
}

#app {
  width: 100%;
  height: 100%;
  overflow-x:hidden ;
  /*overflow:hidden; */
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /*background: #f6f6f6;*/
}
.my-element-1_1s {
  --animate-duration: 1.1s;
}
.my-delay-0_5s {
  --animate-delay: 0.5s;
}
</style>
