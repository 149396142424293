const index = () => import('@/views')
const home = () => import('@/views/home')
const Tracking = () => import('@/views/shipment-tracking')
const routes = [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/',
        component: index,
        children:[
            {
                path: 'home',
                name:'home',
                component: home,
            },
            {
                path: 'tracking',
                name: 'Tracking',
                component: Tracking
            }
        ]
    }
]

export default routes;