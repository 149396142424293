<template>
    <transition name="slide" 
                enter-active-class="animate__animated animate__slideInUp animate__faster"
                leave-active-class="animate__animated animate__fadeOut animate__faster">
		        <div v-show="loading" class="loading_box" style="display:flex;justify-content:center;align-items:center;width:100%;height:100%;background: #ececec;"><img src="@/assets/img/logo.png" width="250"></div>
        </transition>
</template>
<script>
export default{
    name:'loading',
    props:{
        loading:{
            type:Boolean,
            default:false
        }
    }
}
</script>