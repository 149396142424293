<!-- i18n -->
<template>
  <div style="width:80px;">
    <el-select v-model="selectValue" @change="langChange" placeholder="选择语言" style="width:100%;">
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      </el-option>
    </el-select>
  </div>
</template>
 
<script>
export default {
  data() {
    return {
      selectValue: "",
      /*options: [
        {
          value: "cn",
          label: "中文",
        },
        {
          value: "en",
          label: "English",
        },
      ],*/
      options: [
        {
          value: 'cn',
          label: '中文'
        },
        {
          value: 'en',
          label: 'eng'
        },
        {
          value: 'ar',
          label: 'ar'
        },
      ],

    };
  },
  created() {
    let that = this;
    //console.log(localStorage.lang);
    that.selectValue = localStorage.lang == "" ? "cn" : localStorage.lang;
  },
  methods: {
    //语言切换
    langChange(e) {
      // console.log(e)
      localStorage.setItem("lang", e);
      this.$i18n.locale = e;
    },
  },
};
</script>

<style scoped>
::v-deep .el-input__inner{
  border:none;
}
::v-deep .el-input__suffix{
  display:none;
}
::v-deep .el-input--suffix .el-input__inner{
  /*padding-left:5px;
  padding-right:0;*/
  padding:0 0 0 10px;
  font-size: 12px;
  font-family: AlibabaPuHuiTiR;
  font-weight: 400;
  color: #000;
  height:20px;
  line-height: 20px;
}
::v-deep .el-select-dropdown{
  position: absolute;
  top:75px;
}
::v-deep .el-popper .popper__arrow, .el-popper .popper__arrow::after{
  position: static;
}
::v-deep .el-popper[x-placement^=bottom]{
  margin-top:0px !important;
  position: absolute;
    top:70px;
} 
::v-deep .el-popper[x-placement^=bottom] .popper__arrow{
  /*position: absolute;
  left: 30px;*/
  border:none;
}

::v-deep input::-webkit-input-placeholder { 
  color: #000;
} 
::v-deep input::-moz-input-placeholder { 
  color: #000;
} 
::v-deep input::-ms-input-placeholder { 
  color: #000;
}

</style>