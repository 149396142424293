import Vue from 'vue'
import App from './App.vue'
/*import {
	createPinia,
	PiniaVuePlugin
} from 'pinia'*/
import axios from 'axios'
import router from '@/router'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import _ from 'lodash'

// //引入组件库
// import jvuewheel from '@jyeontu/jvuewheel'
// //引入样式
// import '@jyeontu/jvuewheel/lib/jvuewhell.css'
// Vue.use(jvuewheel);

//import floatBtn from '@/components/floatBtn'
import loading from '@/components/loading'

/*import AMap from 'vue-amap'
Vue.use(AMap)
AMap.initAMapApiLoader({
	key: 'eb6387d15436fd609ce7ac0824cd4905',	//申请的key
	plugin: [
		'AMap.Autocomplete',
		'AMap.PlaceSearch',
		'AMap.Scale',
		'AMap.OverView',
		'AMap.ToolBar',
		'AMap.MapType',
		'AMap.PolyEditor',
		'AMap.CircleEditor',
		'AMap.Geolocation',
		'AMap.MarkerClusterer',
	],
	v: '1.4.4'
})*/

import 'animate.css'

import i18n from '@/i18n/langs/i18n'

import langs from "@/components/langs"


//Vue.use(PiniaVuePlugin)

Vue.prototype.$axios = axios
Vue.config.productionTip = false
Vue.prototype.$_=_
//全局注册组件
//Vue.component('my-float-btn', floatBtn)
Vue.component('my-loading', loading)

//const pinia = createPinia() //需要挂载在实例上

Vue.use(Element)

Vue.component('my-lang', langs)

Vue.config.productionTip = false

new Vue({
	router,
	i18n,
	//pinia,
	render: h => h(App),
}).$mount('#app')
